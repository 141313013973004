'use client';

import { Next13ProgressBar } from 'next13-progressbar';
import * as React from 'react';

export function NProgressProvider({ children }: { children: React.ReactNode }) {
  return (
    <>
      {children}
      <Next13ProgressBar
        color="#0A2FFF"
        height="2px"
        options={{ showSpinner: false }}
        showOnShallow
      />
    </>
  );
}
